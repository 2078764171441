<template>
  <div
    id="layout"
    class="h-full"
  >
    <header
      class="lg:fixed lg:w-full z-20 bg-white"
    >
      <LanguageSection />
    </header>
    <div
      class="px-5 lg:pt-14 h-screen lg:px-0"
      :class="{ 'pt-24': needsPadding }"
    >
      <div
        class="h-full"
      >
        <slot />
      </div>
    </div>
    <ConsentTool />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import LanguageSection from '~/sections/LanguageSection.vue'
import { breakKeys } from '~/utils/translation-utils'

const { $i18n: i18n } = useNuxtApp()

useHead({
  title: 'Checkout',
  htmlAttrs: {
    lang: i18n.locale
  }
})

const needsPadding = ref<boolean>(false)

onMounted((): void => {
  breakKeys()
})
</script>
